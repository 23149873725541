import { Component } from '@angular/core';
import { Platform, AlertController, Events, MenuController } from '@ionic/angular';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AuthService } from './services/auth.service';
import { version } from './globals';
import { Storage } from '@ionic/storage';
import { AccountService } from './services/account.service';
import { Device } from '@ionic-native/device/ngx';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { ScheduleService } from './services/schedule.service';
import { fbaseconfig } from './globals';

import { firebase } from '@firebase/app';

//Production
declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home',
      color: 'light',
      bgColor: 'secondary',
      showIfLoggedOut: true
    },
    {
      groupname: 'Prop Exchange',
      showIfLoggedOut: false,
      items: [
        {
          title: 'Exchange',
          url: '/exchange',
          icon: 'swap',
          color: 'light',
          bgColor: 'secondary',
          showIfLoggedOut: false
        },
        {
          title: 'My Props',
          url: '/my-props',
          icon: 'albums',
          color: 'light',
          bgColor: 'secondary',
          showIfLoggedOut: false
        }
      ]
    },
    {
      groupname: 'Gridlock',
      showIfLoggedOut: false,
      items: [
        {
          title: 'New Grid',
          url: '/gridlock',
          icon: 'add',
          color: 'light',
          bgColor: 'secondary',
          showIfLoggedOut: false
        },
        {
          title: 'My Grids',
          url: '/my-grids',
          icon: 'grid',
          color: 'light',
          bgColor: 'secondary',
          showIfLoggedOut: false
        },
      ]
    },
    {
      groupname: 'Establishments',
      showIfLoggedOut: false,
      items: [
        // {
        //   title: 'All Establishments',
        //   url: '/establishments',
        //   icon: 'beer',
        //   color: 'light',
        //   bgColor: 'secondary',
        //   showIfLoggedOut: false
        // },
        {
          title: 'My Establishments',
          url: '/sports-bars',
          icon: 'beer',
          color: 'light',
          bgColor: 'secondary',
          showIfLoggedOut: false
        },
        {
          title: 'My Rewards',
          url: '/your-rewards',
          icon: 'trophy',
          color: 'light',
          bgColor: 'secondary',
          showIfLoggedOut: false
        },
      ]
    },
  ];

  versionNum: any;
  AuthenticatedUser: any;
  loggedIn: boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private auth: AuthService,
    private alertCtrl: AlertController,
    private iab: InAppBrowser,
    private storage: Storage,
    private event: Events,
    private acct: AccountService,
    private device: Device,
    private psh: Push,
    private gooAn: GoogleAnalytics,
    private sched: ScheduleService,
    private menu: MenuController,
    private router: Router,
  ) {
    this.initializeApp();
    this.event.subscribe('AuthenticatedUser', data => {
      this.AuthenticatedUser = data;
      this.loggedIn = true;
    })

    this.event.subscribe('updateWallet', data => {
      console.log(data);
      if(data){
        this.updateWallet();
      }
    })

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      firebase.initializeApp(fbaseconfig);

      if(this.platform.is('cordova')){

        // Only Production
        if (cordova && cordova.plugins && cordova.plugins.Keyboard) {
          cordova.plugins.Keyboard.hideKeyboardAccessoryBar(false);
          cordova.plugins.Keyboard.disableScroll(true);
          console.log('HIII');
        }

        this.checkVersion().then(data => {
          if(!data){
            this.storage.get('access_token').then(data => {
              if(data){
                this.handleAuthenticated();
                this.versionNum = version;
                this.splashScreen.hide();
              }
              else{
                this.event.publish('loggedIn', false);
                this.splashScreen.hide();
                this.storage.clear();
              }
            })
          }
          else{
            this.splashScreen.hide();
            this.updateApp();
          }
        })
      }
      else{
        this.storage.get('access_token').then(data => {
          if(data){
            this.handleAuthenticated();
            this.versionNum = version;
            this.splashScreen.hide();
          }
          else{
            this.event.publish('loggedIn', false);
            this.splashScreen.hide();
            this.storage.clear();
          }
        })
      }



    });
  }

  handleAuthenticated(){
    this.auth.activeUser()
      .subscribe(data => {
        this.AuthenticatedUser = data;
        this.loggedIn = true;
        this.event.publish('AuthenticatedUser', this.AuthenticatedUser);
        this.storage.set('activeUser', this.AuthenticatedUser);
        this.acct.getPendingCount()
          .subscribe(data => {
            this.event.publish('activePropsandGrids', data['total_pending']);
          })


        // if(!this.AuthenticatedUser['first_name'] || !this.AuthenticatedUser['last_name']){
        //   setTimeout(() => {
        //     let modal = this.modalCtrl.create(LearnToPlayComponent, {'user': this.AuthenticatedUser});
        //     modal.present();
        //   }, 500)
        // }

        this.event.publish('loggedIn', true);
        this.loadActiveSports();
        this.splashScreen.hide();
        this.initPushNotification(this.AuthenticatedUser['username']);

        // Production
        this.gooAn.startTrackerWithId('UA-72579820-2')
          .then(() => {
            this.gooAn.setAllowIDFACollection(true).then(()=>{
              this.gooAn.setUserId(this.AuthenticatedUser['id']);
              this.gooAn.setAppVersion(version);
            })
          })
          .catch(e => console.log('Error starting GoogleAnalytics', e));
        },
      err => {
        this.storage.remove('activeUserId');
        this.storage.remove('access_token');
        this.showAlert(err['_body']);
      });
  }

  closeMenu(){
    this.menu.close();
  }

  checkVersion(){
    let promise = new Promise((resolve, reject) => {
      this.auth.checkUpdate()
      .subscribe(data => {
        if(data['current_version'] == version){
          resolve(true);
        }
        else{
          resolve(false);
        }
      })
    })
    return promise;
  }

  async updateApp(){
    let alert = await this.alertCtrl.create({
      header: 'Update App',
      subHeader: 'New version available.',
      buttons: [
        {
          text: 'Update',
          handler: () => {
            this.launch('https://itunes.apple.com/us/app/gridlock-race-to-gridlock/id1335813378?mt=8')
          }
        }
      ]
    })
    await alert.present();
  }

  launch(url){
    let browser = this.iab.create(url, 'location=yes');
    browser.show()
  }

  initPushNotification(username) {
    if (!this.platform.is('cordova')) {
      console.warn("Push notifications not initialized. Cordova is not available - Run in physical device");
      return;
    }
    const options: PushOptions = {
      ios: {
        alert: "true",
        badge: true,
        sound: "true"
      },
      windows: {}
    };
    const pushObject: PushObject = this.psh.init(options);

    pushObject.on('registration').subscribe((data: any) => {
      if (this.platform.is('ios')){
        this.acct.saveAPNS(username + ' - ' + 'iPhone', this.device.uuid,  data.registrationId)
          .subscribe(data => {
            console.log('Status: ' + data);
          })
      }
      if (this.platform.is('android')){
        this.acct.saveGCM(username + ' - ' + 'Android', this.device.uuid,  data.registrationId);
      }
    });

    pushObject.on('notification').subscribe((data: any) => {
      console.log('message', data.message);
    });

    pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
  }

  async showAlert(err){
    let alert = await this.alertCtrl.create({
    header: 'Error',
    message: err,
    buttons: [
      {
        text: 'Okay',
        handler: () => {

        }
      }
    ]
    });
    await alert.present();
  }

  storeActiveSports(data){
    this.storage.set('sports', data);
  }

  loadActiveSports(){
    this.sched.getActiveSports()
    .subscribe(data => {
      if (data){
        this.storeActiveSports(data);
      }
    })
  }

  updateWallet(){
    this.acct.getWallet()
      .subscribe(data => {
        this.AuthenticatedUser.record.available = data;
        console.log(data);
      })
  }

  logout(){
    this.auth.logout();
    this.loggedIn = false;
    this.storage.remove('activeUser');
    localStorage.removeItem('access_token');
    this.storage.remove('access_token');
    this.event.publish('performLogout', true);
    this.router.navigate(['/home/'])
  }


}
