'use strict';

//Development
// export var endpoint = 'http://localhost:8000/api/v1';

//Production
export var endpoint = 'https://www.playgridlock.com/api/v1';

//Test
// export var endpoint = 'https://www.test.playgridlock.com/api/v1';

export var version = '3.2.6';

export var fbaseconfig = {
  apiKey: "AIzaSyDbdw-vCR7eG9zAVFfyrQ8AaDXx-NnMb_k",
  authDomain: "gridlock-feb74.firebaseapp.com",
  databaseURL: "https://gridlock-feb74.firebaseio.com",
  projectId: "gridlock-feb74",
  storageBucket: "gridlock-feb74.appspot.com",
  messagingSenderId: "187689080228"
};
