import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'exchange', loadChildren: './exchange/exchange.module#ExchangePageModule' },
  { path: 'my-props', loadChildren: './my-props/my-props.module#MyPropsPageModule' },
  { path: 'prop/:id', loadChildren: './prop-details/prop-details.module#PropDetailsPageModule' },
  { path: 'sports-bars/bar-manager', loadChildren: './bar-manager/bar-manager.module#BarManagerPageModule' },
  { path: 'sports-bars', loadChildren: './sports-bars/sports-bars.module#SportsBarsPageModule' },
  { path: 'gridlock/build-grid', loadChildren: './build-grid/build-grid.module#BuildGridPageModule' },
  { path: 'my-grids', loadChildren: './my-grids/my-grids.module#MyGridsPageModule' },
  { path: 'grid/:id', loadChildren: './grid-details/grid-details.module#GridDetailsPageModule' },
  { path: 'your-rewards', loadChildren: './your-booty/your-booty.module#YourBootyPageModule' },
  { path: 'profile/:id', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'reward/:id', loadChildren: './reward-details/reward-details.module#RewardDetailsPageModule' },
  { path: 'edit-profile/:id', loadChildren: './edit-profile/edit-profile.module#EditProfilePageModule' },
  { path: 'gridlock', loadChildren: './gridlock/gridlock.module#GridlockPageModule' },
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'establishments', loadChildren: './establishments/establishments.module#EstablishmentsPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
