import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoint } from '../globals';
import { map } from 'rxjs/operators';
import { AuthService } from "./auth.service";


@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  headers: any;

  constructor(private auth: AuthService, private http: HttpClient) { }

  getActiveSports(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get(endpoint + '/sport/', { headers:headers });
    return response
      .pipe(map(res => res))
  }

  getSchedule(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get(endpoint + '/event/', { headers:headers });
    return response
      .pipe(map(res => res))
  }

  getEvent(id){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get(endpoint + '/event/' + id + '/', { headers:headers });
    return response
      .pipe(map(res => res))
  }

  getDayScheduleLeague(leagueId, date){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get(endpoint + '/sport/' + leagueId + '/schedule/?date=' + date, { headers:headers });
    return response
      .pipe(map(res => res))
  }

  getDayScheduleLeagueForComp(leagueId, date, compId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get(endpoint + '/sport/' + leagueId + '/schedule/?date=' + date + '&competition=' + compId, { headers:headers });
    return response
      .pipe(map(res => res))
  }

  getPlayerSched(playerId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get(endpoint + '/player/' + playerId + '/schedule/', { headers:headers });
    return response
      .pipe(map(res => res))
  }

  getPlayerSchedByDay(playerId, date){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get(endpoint + '/player/' + playerId + '/schedule/?date=' + date, { headers:headers });
    return response
      .pipe(map(res => res))
  }

  getScheduleLeague(leagueId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get(endpoint + '/sport/' + leagueId + '/schedule/', { headers:headers });
    return response
      .pipe(map(res => res))
  }

  getLeagues(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get(endpoint + '/league/', { headers:headers });
    return response
      .pipe(map(res => res))
  }

}
