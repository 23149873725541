import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoint } from '../globals';
import { AuthService } from "./auth.service";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  data: any;
  headers: any;

  constructor(public http: HttpClient, private auth: AuthService) {

  }


  saveAPNS(name, device_id, token){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.post( endpoint + '/push/apns/',{'name': name,
                                                            'device_id': device_id,
                                                            'registration_id': token}, { headers: headers });
    return response
      .pipe(map(res => res))
  }

  saveGCM(name, device_id, token){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.post( endpoint + '/push/gcm/',{'name': name,
                                                            'device_id': device_id,
                                                            'registration_id': token}, { headers: headers });
    return response
      .pipe(map(res => res))
  }

  getPendingCount(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/pending_count/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  load(id) {
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/' + id + '/profile/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  loadEditProfile(id){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/' + id + '/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  getWallet(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/wallet/', {headers: headers});
    return response
      .pipe(map(res => res))
  }

  loadRecentActivity(userId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/' + userId + '/recent_activity/', {headers: headers});
    return response
      .pipe(map(res => res))
  }

  pendingProps(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/pending/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  loadMorePending(page){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( page, { headers: headers });
    return response
      .pipe(map(res => res))
  }

  lockedProps(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/locked/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  loadMoreLocked(page){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( page, { headers: headers });
    return response
      .pipe(map(res => res))
  }

  completedProps(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/completed/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  loadMoreCompleted(page){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( page, { headers: headers });
    return response
      .pipe(map(res => res))
  }

  getAvatars(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/avatars/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  update(userId, data){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.patch( endpoint + '/user/' + userId + '/', data, { headers: headers });
    return response
      .pipe(map(res => res))
  }

  followersCount(userId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/' + userId + '/followers_count/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  followingCount(userId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/' + userId + '/following_count/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  follow(userId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.post( endpoint + '/user/start_following/',{to_user: userId}, {headers: headers});
    return response
      .pipe(map(res => res))
  }

  unFollow(userId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.post( endpoint + '/user/stop_following/',{to_user: userId}, {headers: headers});
    return response
      .pipe(map(res => res))
  }

  followingList(userId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/' + userId + '/following/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  followersList(userId){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/' + userId + '/followers/', { headers: headers });
    return response
      .pipe(map(res => res))
  }

  loadMoreActivity(url){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( url, { headers: headers });
    return response
      .pipe(map(res => res))
  }

  searchUsers(searchTerm){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.auth.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/?search=' + searchTerm , { headers: headers });
    return response
      .pipe(map(res => res))
  }
}
