import { Injectable } from '@angular/core';
import {endpoint} from "../../app/globals";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';

export class User {
  username: string;
  access_token: string;
  constructor(email: string, token: string) {
    this.username = email;
    this.access_token = token;
  }
}

// Developent
// const authConfig = {
//   client_id: '7bn8VpQuQsoOsFDFO7U5P3m8hPthRHx27ySfo5qJ',
//   grant_type: 'password',
//   username: '',
//   password: ''
// };

// Production
const authConfig = {
  client_id: 'PYrH9KSr4q0hSfzbF0XIFQFX9HbDkW5MLnvBPtua',
  grant_type: 'password',
  username: '',
  password: ''
};

// Test Server
// const authConfig = {
//   client_id: 'FYHtp2JwPMT2yoywBEjFu6I4EiPN3oyXvfpfsTkO',
//   grant_type: 'password',
//   username: '',
//   password: ''
// };

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: User;
  public isAuthenticated: boolean;
  headers: any;

  constructor(public http: HttpClient, private storage: Storage) {
    this.currentUser = new User('', '');
    let token = localStorage.getItem('access_token');
    this.isAuthenticated = !!token;
    if (this.isAuthenticated) {
      this.currentUser.access_token = token;
    }

  }

  login(username, password) {
    let headers = {
      'Content-Type':'application/json'
    };
    authConfig['username'] = username;
    authConfig['password'] = password;
    let response = this.http.post(endpoint + '/oauth/token/', authConfig, {headers:headers} )
    return response
    .pipe(map((res: any) => {
      if (res.access_token) {
        // Find a way around storing this variable in two places
        localStorage.setItem('access_token', res.access_token);
        this.storage.set('access_token', res.access_token);
        this.isAuthenticated = true;
        this.currentUser = new User(username, res.access_token);
        return true;
      }
      else{
        return false;
      }
    }))

  }

  activeUser(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/user/active/', {headers:headers});
    return response
    .pipe(map(res => res))
  }

  getTokenForHeader() {
    let token = 'Bearer Prop ' + localStorage.getItem('access_token');
    return token;
  }

  pushToken(name, deviceId, token, platform){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.getTokenForHeader()
    };
    if (platform == 'ios'){
      let response = this.http.post(endpoint + '/push/apns/', {'name':name, 'device_id': deviceId, 'registration_id': token}, {headers:headers});
      return response
        .pipe(map(res => res))
    }
    if (platform == 'android'){
      let response = this.http.post(endpoint + '/push/gcm/', {'name':name, 'device_id': deviceId, 'registration_id': token}, {headers:headers});
      return response
        .pipe(map(res => res))
    }
  }

  checkUpdate(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/app/version/', {headers:headers});
    return response
    .pipe(map(res => res))
  }

  convert(token, token_secret, backend){

    let thirdpartytoken = 'oauth_token=' + token + '&oauth_token_secret=' + token_secret;

    const convertConfig = {
      client_id: authConfig.client_id,
      grant_type: 'convert_token',
      backend: 'twitter',
      token: thirdpartytoken
    };

    let headers = {
      'Content-Type':'application/json'
    };

    let response = this.http.post( endpoint + '/oauth/convert-token/', convertConfig, {headers:headers});
    return response
    .pipe(map((res: any) => {
      localStorage.setItem('access_token', res.access_token);
      this.storage.set('access_token', res.access_token);
    }))
  }

  logout(){
    localStorage.removeItem('access_token');
    this.storage.remove('access_token');
    this.storage.remove('activeUserId');
    this.storage.clear();
    this.currentUser.access_token = '';
    this.isAuthenticated = false;
  }

}
